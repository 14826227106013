import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "scrollbar"
    }}>{`Scrollbar`}</h1>
    <p>{`Class to customize the scrollbar`}</p>
    <div className='scrollbar border-gray-light p-3 border-radius mb-3' style={{
      "width": "100%",
      "maxHeight": "300px",
      "border": "2px solid transparent",
      "overflowY": "auto"
    }}>
  <p className='text-justify'>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse elementum facilisis dui sed hendrerit. Aliquam non massa vel orci finibus euismod. Phasellus consequat, tortor et consequat mattis, sem est tempus dui, nec rhoncus eros libero vel odio. Cras finibus aliquet urna, a dignissim lectus posuere id. Phasellus aliquet nisl ipsum, quis vestibulum felis egestas in. Maecenas nibh sapien, egestas et fermentum id, maximus vehicula justo. Praesent quis arcu non urna mollis tincidunt. Pellentesque pharetra mollis lorem. Nam congue tellus at vehicula venenatis. Morbi hendrerit at magna eget laoreet. Mauris rutrum tortor turpis. Cras varius justo et velit hendrerit bibendum. Ut ac tristique diam. Proin et malesuada est, nec efficitur felis. Vestibulum tempor euismod congue.
    Phasellus at tincidunt felis, ac tincidunt nisi. In quis fermentum lorem. Nam vitae felis tincidunt, vehicula tellus id, malesuada lectus. Donec congue condimentum lectus ut rhoncus. Ut elementum feugiat arcu, non vestibulum ipsum rutrum ut. Maecenas egestas nibh est, quis tempor ligula porttitor maximus. Suspendisse viverra justo hendrerit ipsum congue, vel molestie nunc pharetra. Proin sapien lorem, molestie vitae quam non, commodo vulputate erat. Fusce molestie felis at tellus ultrices condimentum.
    Sed eleifend, ligula at egestas pellentesque, elit felis molestie leo, quis imperdiet libero sapien sit amet tellus. Sed sagittis ex odio, quis ultricies orci consectetur a. Suspendisse placerat tempus malesuada. Nulla ultrices aliquet justo, vitae eleifend lectus placerat id. Nulla elementum non risus ut gravida. Fusce quis rhoncus ipsum, at hendrerit felis. Aenean congue, dolor sed sollicitudin hendrerit, sem diam semper purus, et porttitor eros mi quis dui. Vestibulum dictum accumsan urna, et gravida est pulvinar dapibus. Suspendisse laoreet sapien massa, sed efficitur leo bibendum id. Duis id est ac ex pharetra viverra. Nulla facilisi. Integer mauris sapien, fermentum quis mauris quis, aliquet egestas mauris. Sed ac urna lectus.
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse elementum facilisis dui sed hendrerit. Aliquam non massa vel orci finibus euismod. Phasellus consequat, tortor et consequat mattis, sem est tempus dui, nec rhoncus eros libero vel odio. Cras finibus aliquet urna, a dignissim lectus posuere id. Phasellus aliquet nisl ipsum, quis vestibulum felis egestas in. Maecenas nibh sapien, egestas et fermentum id, maximus vehicula justo. Praesent quis arcu non urna mollis tincidunt. Pellentesque pharetra mollis lorem. Nam congue tellus at vehicula venenatis. Morbi hendrerit at magna eget laoreet. Mauris rutrum tortor turpis. Cras varius justo et velit hendrerit bibendum. Ut ac tristique diam. Proin et malesuada est, nec efficitur felis. Vestibulum tempor euismod congue.
    Phasellus at tincidunt felis, ac tincidunt nisi. In quis fermentum lorem. Nam vitae felis tincidunt, vehicula tellus id, malesuada lectus. Donec congue condimentum lectus ut rhoncus. Ut elementum feugiat arcu, non vestibulum ipsum rutrum ut. Maecenas egestas nibh est, quis tempor ligula porttitor maximus. Suspendisse viverra justo hendrerit ipsum congue, vel molestie nunc pharetra. Proin sapien lorem, molestie vitae quam non, commodo vulputate erat. Fusce molestie felis at tellus ultrices condimentum.
    Sed eleifend, ligula at egestas pellentesque, elit felis molestie leo, quis imperdiet libero sapien sit amet tellus. Sed sagittis ex odio, quis ultricies orci consectetur a. Suspendisse placerat tempus malesuada. Nulla ultrices aliquet justo, vitae eleifend lectus placerat id. Nulla elementum non risus ut gravida. Fusce quis rhoncus ipsum, at hendrerit felis. Aenean congue, dolor sed sollicitudin hendrerit, sem diam semper purus, et porttitor eros mi quis dui. Vestibulum dictum accumsan urna, et gravida est pulvinar dapibus. Suspendisse laoreet sapien massa, sed efficitur leo bibendum id. Duis id est ac ex pharetra viverra. Nulla facilisi. Integer mauris sapien, fermentum quis mauris quis, aliquet egestas mauris. Sed ac urna lectus.
  </p>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class='scrollbar'>
...
</div>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      